module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Генератор жалоб и обращений Skarga.help","short_name":"Skarga.help","start_url":"/","background_color":"#c53F3F","theme_color":"#c53F3F","display":"standalone","icon":"static/images/favicon.svg","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e4fde48527663d4b8fa57df7b4ab74c1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QD7L90P647"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"send_page_view":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
